import * as React from "react"
import { graphql } from 'gatsby';
import { DateTime } from "luxon";
import { useLocation } from "@reach/router"

import Link from 'components/link';
import Layout from 'components/templates/Layout';
import TopHalf from "components/templates/neighborhood/TopHalf";
import Crowdriff from "components/templates/global/Crowdriff";
import NotFound from 'pages/404';

import useLiveData from 'hooks/useLiveData';

import zparse from "helpers/zparse";

const EventsEventGet = props => {
	// const [ dateString, setDateString ] = React.useState('');
	// const [ timeString, setTimeString ] = React.useState('All Day');
	const { edges: nodes } = props.data.allEventsEvent;
	const events = nodes.map(({ node }) => node);
	let event = events[0];
	const today = DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
	const location = useLocation();
	
	const apiParams = {
		deleted: false, archived: false, approved: true, _join: "Neighborhood,Category"
	};
	if (event)apiParams.id = event.id;
	else {
		apiParams.permalink = location.pathname.split('/').filter(item => item.trim()).slice(1, 2).join('');
	}

	const { statusText: providerStatusText, status: providerStatus, response: providerResponse } = useLiveData({
		type: 'EventsEvent',
		staticData: props.data,
		apiParams: apiParams,
	});

	if (providerStatus < 2) return null; // This prevents any flash of content until the API is loaded -- but if the API takes a while to respond the effect would be the page loads slower. You could also use state to reveal content with a transition
	if ((providerResponse.data.length > 0 || !!event !== false) && providerStatusText === 'LOADED') {
		event = providerResponse.data[0];
		event.dates = event.dates.filter((dateBlock) => {
			const actualDate = DateTime.fromISO(dateBlock.date, {zone: 'utc'});
			const diff = actualDate.diff(today, 'days');
			return diff.values.days >= 0;
		});
		if (event.dates.length === 0)event = null;
	}
	if (!!event === false && providerStatus >= 2) return <NotFound />;

	let dateString = '';
	let timeString = '';

	let passedStartDate = null;
	let passedEndDate = null;
	let passedTime = null;
	const dateFormat = 'EEE, MMM d';

	const neighborhoods = event.Neighborhood.map(({ object }) => object);

	const urlParts = location.pathname.split('/').filter(item => item).splice(2); // Grab only the date & time from the URL
	if (urlParts.length > 0) {
		const dateSeps = (urlParts[0].match(/-/g)||[]).length;
		if (dateSeps === 2) {
			passedStartDate = DateTime.fromISO(urlParts[0], {zone: 'utc'});
			passedEndDate = passedStartDate;
		} else {
			passedStartDate = DateTime.fromISO(urlParts[0].substr(0, 10), {zone: 'utc'});
			passedEndDate = DateTime.fromISO(urlParts[0].substr(11, 10), {zone: 'utc'});
		}
		if (urlParts.length > 1) {
			event.dates.forEach((block) => {
				if (block.date === passedStartDate)passedTime = block.time;
			})
		}
	}

	const now = new Date();
	if (passedTime) {
		// setTimeString(passedTime);
		timeString = passedTime;
	} else if (event.dates && event.dates.length > 0 && event.dates[0].time) {
		// setTimeString(event.dates[0].time);
		timeString = event.dates[0].time;
	} else {
		// setTimeString('All Day');
		timeString = 'All Day';
	}

	if (event.timeDetails && timeString) {
		timeString += `, ${event.timeDetails}`;
	} else if (event.timeDetails) {
		timeString = event.timeDetails;
	}

	if (passedStartDate && passedEndDate) {
		const diff = passedStartDate.diff(passedEndDate, ['days']);

		if (passedStartDate.monthShort !== passedEndDate.monthShort) {
			// format like Wed, May 8 - Thu Jun 6
			dateString = `${passedStartDate.toFormat('EEE, MMM d')} - ${passedEndDate.toFormat('EEE, MMM d')}${passedEndDate.year !== now.getFullYear() ? ` ${passedEndDate.toFormat('y')}` : ''}`;
		} else if (diff.values.days >= 1) {
			// format like Wed, May 8 - 12
			dateString = `${passedStartDate.toFormat('EEE, MMM d')} - ${passedEndDate.toFormat('d')}${passedEndDate.year !== now.getFullYear() ? ` ${passedEndDate.toFormat('y')}` : ''}`;
		} else {
			dateString = passedStartDate.toFormat(dateFormat);
		}
	} else if (event.dates && event.dates.length > 0 && event.dates[0].date) {
		dateString = DateTime.fromISO(event.dates[0].date, { zone: 'utc' }).toFormat(dateFormat);
	}

	return (
		<Layout headerBackgroundImage={event?.photo}>
			<TopHalf
				title={event.name}
				rawContent={
					<>
						<div style={{ width: "100%" }} className="grid-x grid-padding-x align-center">
							<div className="cell small-12 medium-offset-1 medium-5 small-order-1 medium-order-2">
								<div className="description-wrapper">
									<h3 className="description">Event Description</h3>
									{event?.description ? zparse(event.description) : ''}
									<div style={{ marginTop: "1rem" }}>
										{event?.poster && <Link className="button" target="_blank" to={event?.poster}>
											See Event Poster
										</Link>}
									</div>
									{event.dates && event.dates.length > 1 && (
										<>
											<h4 className="mini-title upper">{event.recurringEvent ? 'Recurring' : 'Multiday'} Event</h4>
											<div className="grid-x grid-padding-x small-up-3">
												{event.dates.map((date, index) => {
													const blockDate = DateTime.fromISO(date.date, {zone: 'utc'});
													const diffNow = blockDate.diff(today, 'days');
													if (diffNow.values.days < 0)return null;
													let selected = false;

													if (passedStartDate && !passedEndDate) {
														const diffFromPassedStartDate = blockDate.diff(passedStartDate, 'days');
														if (diffFromPassedStartDate.values.days === 0)selected = true;
													}
													if (passedStartDate && passedEndDate) {
														const diffFromPassedStartDate = blockDate.diff(passedStartDate, 'days');
														const diffFromPassedEndDate = blockDate.diff(passedEndDate, 'days');
														if (diffFromPassedStartDate.values.days >= 0 && diffFromPassedEndDate.values.days <= 0)selected = true;
													}
													return (
														<div key={index} className="cell">
															<div className={`date-block ${selected ? 'selected' : ''}`}>
																<div className="dow">{blockDate.toFormat('EEEE')}</div>
																<div className="date">{`${blockDate.toFormat('MMM d')}${blockDate.year !== now.getFullYear() ? ` ${blockDate.toFormat('y')}` : ''}`}</div>
																<div className="time">{date.time || 'All Day'}</div>
															</div>
														</div>
													);
												})}
											</div>
										</>
									)}
								</div>
							</div>
							<div className="cell medium-5 details-wrapper small-order-2 medium-order-1">
								<div className="grid-x grid-padding-x">
									<div className="cell">
										<div className={`details ${event.featured ? 'featured' : ''}`}>
											{event.featured && (
												<div className="featured-flag">
													<img alt="Featured" src={`/img/star.svg`} />
													Featured Event
												</div>
											)}
											<h4 className="upper tall">Date</h4>
											{dateString}
											<br />
											{timeString}
											<br />
											<br />
											{event.cost && (
												<>
													<h4 className="upper tall">Admission Price</h4>
													<div>{event.cost}</div>
													<br />
												</>
											)}

											{event.street || event.suite || event.city ? (
												<>
													{(() => {
														const address = `${event.street}, ${neighborhoods.map((n) => n.name).join(', ')} FL ${event.zip || ''}`;
														const formattedAddress = address.replace(/ /g, '+');
														return (
															<>
																<h4 className="upper tall">Address</h4>
																{address && (
																	<Link target="_blank" href={`https://www.google.com/maps/dir/Current+Location/${formattedAddress}/`}>
																		{event.street && <>{event.street}<br /></>}
																		{event.suite && <>{event.suite}<br /></>}
																		{event.city || neighborhoods.map((n) => n.name).join(', ')}
																		, FL {event.zip || ''}
																	</Link>
																)}
																<br />
																<br />
															</>
														);
													})()}
												</>
											) : null}

											{neighborhoods.length > 0 && (
												<>
													<h4 className="upper tall">{`Neighborhood${neighborhoods.length > 1 ? 's' : ''}`}</h4>
													{neighborhoods.map((current, index) => (
														<React.Fragment key={index}>
															{current.featureSnippet ? (
																<Link href={`/neighborhoods/${current.permalink}/`}>{current.name}</Link>
															) : (
																<>{current.name}</>
															)}
															{neighborhoods.length > 1 && index < neighborhoods.length - 1 ? ', ' : ''}
														</React.Fragment>
													))}
													<br />
													<br />
												</>
											)}

											{(event.email || event.phone) && (
												<>
													<h4 className="upper tall">Contact Information</h4>
													{event.email && <a className="contact" href={`mailto:${event.email}`}>{event.email}</a>}
													<br />
													{event.phone && <a className="contact" href={`tel:1-${event.phone}`}>{event.phone}</a>}
													<br />
												</>
											)}
										</div>
										{event?.website && <Link className="button blue" to={event?.website}>{event.linkText ? event.linkText : 'Visit Website'}</Link>}
									</div>
								</div>
							</div>
						</div>
					</>
				}
			/>
			<Crowdriff hash={event.crowdriff ? event.crowdriff : `980a78db817a3319`} />
		</Layout>
	);
};

export default EventsEventGet;

export { Head } from 'components/templates/Head';

export const query = graphql`
query eventGetQuery ($permalink: String) {
	allEventsEvent(
		filter: {permalink: {eq: $permalink}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
		  id
		  city
		  contactEmail
		  contactName
		  contactPhone
		  cost
		  dates {
			time
			startTime
			endTime
			date
			realDate
		  }
		  description
		  email
		  featured
		  firstDate
		  firstTime
		  intro
		  isMultiDay
		  isRecurring
		  linkText
		  location
		  name
		  poster
		  permalink
		  phone
		  photo
		  website
		  crowdriff
		  recurringEvent
		  registrationDetail
		  registration
		  registrationEmail
		  registrationLimit
		  registrationLimitTable {
			date
			limit
		  }
		  registrationMessage
		  showDate
		  sort
		  state
		  street
		  suite
		  timeDetails
		  zip
		  Category {
			object {
			  name
			  permalink
			}
		  }
		  Neighborhood {
			object {
			  name
			  permalink
			}
		  }
		}
	  }
	}
}
`